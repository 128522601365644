/* ............

.........................................BookedFlightSearchInput................................................................................... */

.SearchInputBox {
  margin: 5vh 5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  width: 90%;

  color: #273f87;
}

.SearchInputBox:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.HistoryHeading {
  background-color: #273f87;
  color: white;
  text-align: center;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  color: #273f87;
}

.column {
  display: flex;
  /* justify-content: space-between; */
  margin: 1vh 1vw;
}

.HistoryComment {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #273f87;
}
/* .................................................... /.................................................................................. */
/* .................................................... Main Home Page.................................................................................. */
.HomePage {
  width: 100vw;
  height: 100vh;
}
.SignInContainer {
  background-color: #f3d992;
  background-image: url("/src/assets/images/delivery-man-holds-box-yellow-background-with-boxes.png");
  /* background-repeat: no-repeat; */
  /* background-position:left; */
  background-size: cover;
  display: flex;

  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 3.8vh 2vw;
  color: black;
  height: 97vh;
}
.SignInBox {
  margin: 1vh;
  background-color: white;
  border-radius: 10px;
}
.Greetings {
  color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.Greeting {
  color: white;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
}
.fontSizeBolder {
  font-size: 30px;
}
.FontSizeLarger {
  font-size: 50px;
}
/* ..........................................blinking CSS.................................................................... */
.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

/* ............................................Service Navbar .................................................................. */
.ServicesNavbarBox {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  background-color: #899bdb;
  padding-top: 2vh;
  padding-left: 2vw;
  padding-right: 2vw;
}
.log:hover {
  box-shadow: 0 14px 36px rgba(255, 254, 254, 0.975),
    0 10px 10px rgba(0, 0, 0, 0.22);
  cursor: pointer;
}
.name {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  /* text-align: center; */
}

.log {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 5vw;
  height: 12vh;
  background-color: #273f87;
  border-radius: 5px;
}
.name {
  /* color: white; */
  padding: 1vh 0vw;
  font-weight: bolder;
  color: #273f87;
}
/* ...................................................OverViewPage..................................................................
 */

.OverViewContainer {
  width: 80vw;
  height: 100vh;
  /* margin: 0vh 0vw; */
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  /* box-shadow: 5px 5px 20px; */
  background-color: white;
  padding: 15px;
  margin: 15px;
}
.DashBoaredChildContainer {
  /* box-shadow: 5px 5px 20px; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.OverViewChildContainer {
  margin: 10px;
  width: 95%;
  height: 95%;
  /* box-shadow: 5px 5px 20px; */
}

.OverViewChildContainer {
  margin: 10px;
  width: 95%;
  height: 95%;
  /* box-shadow: 5px 5px 20px; */
}
.TodaysUpdateBox {
  width: 95%;
  height: 70%;
  margin: 0% 2%;
  border-radius: 5px;
  /* box-shadow: 5px 5px 20px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.UpdateBox {
  width: 20%;
  height: 100%;
  box-shadow: 5px 5px 10px;
  color: rgb(216, 208, 208);
  margin: 5px;
  border-radius: 15px;
  border-top: 3px solid aqua;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
}
.UpdateMainBox {
  height: 25%;
  /* box-shadow: 5px 5px 35px; */
  color: rgb(216, 208, 208);
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.UpdateText {
  color: #365ed8;
  padding-top: 10px;
  padding-left: 10px;
  font-weight: bolder;
}
.IconBox {
  width: 40px;
  height: 40px;
  padding: 2%;
  background-color: #c2c4ce;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.UpdateTextBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Side Bar Css >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

.SideBarBox {
  width: 8vw;
  height: 100vh;
  background-color: #273f87;
  color: #f08c27;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 0px;
}
.SideBar {
  width: 7vw;
  height: 12vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #899bdb;
}
/* .................................new Order Page........................................... */
.mainNewOrderContainer {
  width: 93vw;
  height: 100vh;
  justify-content: center;
  align-items: flex-start;
}
.CourierOrderMainBox {
  /* background-image: url('./assets/images/delivery-man-holds-box-yellow-background-with-boxes.jpg'); */
  background-color: #21da5e;
  width: 100%;
  height: 100%;
  background-size: cover;
  padding: 10vh 5vw;
  justify-content: flex-start;
  align-items: flex-start;
}
.CourierOrderBox {
  /* width:50vw; */
  height: 60vh;
  background-color: aliceblue;
  border-radius: 10px;
  padding: 25px 10px;
}
.FromToSearch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 100px;
}
.InputBox {
  margin: 5px 2px;
}
.CommonButton {
  width: 10vw;
  height: 4vw;
  background-color: #f08c27;
  color: #273f87;
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 5px;
}
.CommonButton:hover {
  background-color: #273f87;
  color: #f08c27;
  cursor: pointer;
}
/* .........Partner Page ...............


*/
.MainAvailablePartnerPage {
  width: 98vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.CourierDetails {
  width: 90vw;
  display: flex;
  height: 10vh;
  justify-content: space-around;
  align-items: center;
  background-color: #3e4a99;
  color: #f5b575;
  border-radius: 10px;
  margin: 10px;
}
.AllPartnerBox {
  padding: 10px;
}
.IndPartnerBox {
  width: 80vw;
  height: 10vh;
  border-radius: 10px;
  background-color: aliceblue;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  margin: 20px 5px;
  box-shadow: 5px 5px 5px;
  color: rgb(202, 193, 193);
}
.DeliveryType {
  font-weight: bold;
  font-size: large;
  color: #21da5e;
  width: 30vw;
}
.price {
  font-weight: bold;
  font-size: large;
  color: #273f87;
}
/* ...............................................multilinknavbarcss.....................AllPartnerBox */
/* ...................................Not Found Page .......................................................................Not */

/*======================
    404 page
=======================*/

.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 80vh;
  width: 100vw;
  background-position: center;
  display: flex;
  justify-content: center;
  align-content: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.four_zero_four_bg h1 {
  font-size: 80px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.four_zero_four_bg h3 {
  font-size: 80px;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100vw;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* .....................Skelaton...................................... */
.skeleton {
  background: linear-gradient(90deg, #e8e8e8 0px, #f8f8f8 40px, #e8e8e8 80px);
  /* background-size: 350px; */
  /* width: 100%; */
  /* height: 1.45rem; */
  /* border-radius: 3px; */
  /* margin-top: 1.5rem; */
  animation: animation 2s infinite;
}

@keyframes animation {
  0% {
    background-position: -80px;
  }
  40%,
  100% {
    background-position: 310px;
  }
}
